import React,  { useState } from "react"
import Layout from '../components/layout'
// import { StaticImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import Seo from 'gatsby-plugin-wpgraphql-seo';
import Subscribe from '../components/subscribe'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

const SingleNewsletter = ({ newsletter }) => {
	const [openState, setOpenState] = useState(0);

	const stateToggle = () => {
		return openState ? setOpenState(0) : setOpenState(1)
	}

	return (
		<li className={`singleNewsletter ${openState ? 'opened' : ''}`}>
			<div
				className="newsletterHeader"
				onClick={stateToggle}
				onKeyDown={stateToggle}
				role="button"
				tabIndex="0"
			>
				<span className="date">{newsletter.newsletterExtra.date}</span>
				<h2>{newsletter.title}</h2>
				<FontAwesomeIcon icon={faChevronDown} size="1x" id="chevron" />
			</div>
			<div className="excerpt" dangerouslySetInnerHTML={{ __html: newsletter.content }}></div>
		</li>
	)
}

const NewsletterPage = ({ data }) => {

	const page = data.wpMainPage;
	const newsletters = data.allWpNewsletter.nodes;

	const sortedNewsletters = newsletters.sort((a, b) => {
		let newsdate1 = new Date(a.newsletterExtra.date);
		let newsdate2 = new Date(b.newsletterExtra.date);
		// return (newsdate2 > newsdate1 ? 1 : 0)
		return newsdate2 - newsdate1;
	});



	return (
		<Layout title={'Newsletters archive'} breadcrumbs={[['Newsletter','#']]}>
			<Seo post={page} />
			<div id="newsletterWrapper">
				<section className="content" id="newsletter">
					<p dangerouslySetInnerHTML={{ __html: page.content }}></p>
					<div>
						<ul id="newslettersList">
							{sortedNewsletters.map((newsletter, i) => {
								if (i >= 10) return;
								return (
									<SingleNewsletter key={i} newsletter={newsletter}/>
								)
							})}
						</ul>
					</div>
				</section>
			</div>
			<Subscribe/>
		</Layout>
	)
}

export default NewsletterPage


export const query = graphql`
	query NewsletterPageQuery {
		allWpNewsletter(limit: 200, sort: {order: ASC, fields: menuOrder})  {
			nodes {
				title
				content
				newsletterExtra {
					date
				}
			}
		}
		wpMainPage(databaseId: {eq: 92}) {
			content
			nodeType
			title
			uri
			seo {
				title
				metaDesc
				focuskw
				metaKeywords
				metaRobotsNoindex
				metaRobotsNofollow
				opengraphTitle
				opengraphDescription
				opengraphImage {
					altText
					sourceUrl
					srcSet
				}
				twitterTitle
				twitterDescription
				twitterImage {
					altText
					sourceUrl
					srcSet
				}
				canonical
				cornerstone
				schema {
					articleType
					pageType
					raw
				}
			}
		}
	}
`;